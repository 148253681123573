import RenLink from "../shared/RenLink";
import Link from "next/link";
import { useRENAuth } from "helpers/security/ren-auth-client-side";

const ProfileDropdownList = () => {
  const { renUser } = useRENAuth();

  const extraMenuItems = (
    <>
      {renUser.hasRole("rensystemer.kundeadministrator") && (
        <li>
          <RenLink
            href={`${process.env.NEXT_PUBLIC_REN_SYSTEMS_BASE_URL}/kundesystem/views/user/userList.xhtml`}
          >
            Kundesystem
          </RenLink>
        </li>
      )}

      {renUser.hasRole("rensystemer.kursadministrator") && (
        <>
          <li>
            <RenLink
              href={`${process.env.NEXT_PUBLIC_REN_SYSTEMS_BASE_URL}/Kurssystem`}
            >
              Kurssystem
            </RenLink>
          </li>
          <li>
            <RenLink href={"https://checkin.no"}>Checkin</RenLink>
          </li>
        </>
      )}

      {renUser.hasRole("pmas.kontrolladministrator") && (
        <li>
          <RenLink
            href={`${process.env.NEXT_PUBLIC_REN_SYSTEMS_BASE_URL}/pmas`}
          >
            Stikkprøvekontroll
          </RenLink>
        </li>
      )}

      {renUser.hasRole("rensystemer.kundeadministrator") && (
        <li>
          <RenLink href={`/innlogget/renblad-html/status`}>
            RENblad-statusside
          </RenLink>
        </li>
      )}
    </>
  );

  const extraMenuItemsAvailable = extraMenuItems.props.children.some(c => c);

  return (
    <>
      {renUser.hasRole("rensystemer.brukeradministrator") && (
        <li>
          <RenLink
            href={`${process.env.NEXT_PUBLIC_REN_SYSTEMS_BASE_URL}/kundesystem`}
          >
            Mitt selskap
          </RenLink>
        </li>
      )}

      <li>
        <RenLink
          href={`${process.env.NEXT_PUBLIC_REN_SYSTEMS_BASE_URL}/kundesystem/views/profile/profile.xhtml`}
        >
          Brukerinnstillinger
        </RenLink>
      </li>

      {extraMenuItemsAvailable && (
        <li className="c-menu__profile-separator">
          <hr />
        </li>
      )}

      {extraMenuItems}

      <li>
        <Link className="c-login-button" href="/logg-ut">
          Logg ut
        </Link>
      </li>
    </>
  );
};

export default ProfileDropdownList;
